import React from "react"
import Base from "../../components/templates/Base"
import Banner from "../../components/organisms/banner/Banner"
import Breadcrumbs from "../../components/organisms/breadcrumbs/Breadcrumbs"
import Content from "../../components/particles/Content"
import { graphql, Link } from "gatsby"
import SubHeading from "../../components/atoms/SubHeading/SubHeading"
import { randomID } from "../../components/particles/helpers"
import styled from "@emotion/styled"
import { GatsbyImage } from "gatsby-plugin-image";
import theme from "../../components/particles/Theme"
import Sidebar from "../../components/molecules/Sidebar/Sidebar"
import TertiaryButton from "../../components/atoms/Buttons/TertiaryButton/TertiaryButton"

const StyledBlogs = styled.section`
  margin: 5rem 0;
  display: grid;
  grid-template-columns: auto 360px;
  grid-gap: 5rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    display: flex;
  }

  section.blogs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 5rem;

    article {
      padding-bottom: 70px;
      position: relative;

      @media (max-width: ${theme.breakpoints[1]}) {
        text-align: center;
        grid-column: 1/3;
        padding: 3rem;

        box-shadow: 5px 5px 3px #ddd;
        border: 2px solid #ddd;
      }

      a:not(.heading) {
        position: absolute;
        bottom: 20px;
        width: 150px;

        @media (max-width: ${theme.breakpoints[1]}) {
          position: relative;
          bottom: 0;
        }
      }

      a.heading {
        color: ${theme.colors.bodyFont};
        transition: color 0.3s ease;
      }
      a.heading:hover {
        color: ${theme.colors.primaryHover};
      }
      &.main-blog {
        grid-column: 1/3;
      }
    }
  }
`

const BlogPage = ({ data }) => {
   const posts = data.allWpPost.nodes
  return (
    <Base title="Blogs">
      <Banner title="Blogs" />
      <Breadcrumbs />
      <Content>
        <StyledBlogs>
          <section className="blogs">
            {posts.map((post, idx) => (
              <article
                key={randomID()}
                className={idx === 0 ? `main-blog` : `blogs`}
              >
                {idx === 0 && post.featuredImage ? (
                  <GatsbyImage
                    image={post.featuredImage.node.localFile.childImageSharp.gatsbyImageData}
                    alt={post.featuredImage.node.altText}
                    style={{ maxHeight: `400px`, marginBottom: `2rem` }} />
                ) : null}
                <Link className="heading" to={`/blogs${post.uri}`}>
                  <SubHeading title={post.title} />
                </Link>
                <div dangerouslySetInnerHTML={{ __html: post.excerpt }} />
                <TertiaryButton url={`/blogs${post.uri}`} color="white">
                  Read More
                </TertiaryButton>
              </article>
            ))}
          </section>
          <div>
            <Sidebar />
          </div>
        </StyledBlogs>
      </Content>
    </Base>
  );
}

export const pageQuery = graphql`query getBlogs {
  allWpPost(limit: 100, sort: {fields: date, order: DESC}) {
    nodes {
      id
      excerpt
      title
      uri
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
      author {
        node {
          firstName
          lastName
        }
      }
    }
  }
}
`

export default BlogPage
