import React from "react"
import StyledSidebar from "./Sidebar.style"
import SubHeading from "../../atoms/SubHeading/SubHeading"
import { randomID } from "../../particles/helpers"
import { Link } from "gatsby"
import { useBlogData } from "../../particles/hooks/useBlogData"

const Sidebar = () => {
  const recentBlogs = useBlogData()

  return (
    <StyledSidebar>
      <SubHeading title="Featured Blogs" style={{"font-size": "2em", marginBottom: "1em"}} />
      {recentBlogs.map(post => (
		<div>
        <article key={randomID()} style={{marginTop: "1em"}}>
          <Link to={`/blogs${post.uri}`}>
            <p><b>{post.title}</b></p>
          </Link>
        </article>
		<hr style={{borderBottomWidth: "2px", borderColor: "rgba(221,221,221,1)", borderStyle: "solid"}}></hr>
		</div>
      ))}
    </StyledSidebar>
  )
}

export default Sidebar
