import { useStaticQuery, graphql } from "gatsby"

export const useBlogData = () => {
  const wordpress = useStaticQuery(graphql`
    query getSidebarBlogs {
      allWpPost(limit: 5, sort: {fields: date, order: DESC}) {
        nodes {
          id
          excerpt
          title
          uri
          author {
            node {
              firstName
              lastName
            }
          }
        }
      }
    }
  `)

  return wordpress.allWpPost.nodes
}
