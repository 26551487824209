import styled from "@emotion/styled"
import theme from "../../particles/Theme"

const StyledSidebar = styled.section`
  background: rgba(5, 5, 5, 0.05);
  padding: 3rem 2rem;

  @media (max-width: ${theme.breakpoints[1]}) {
    display: none;
  }

  article {
    margin-top: 5rem;

    h4 {
      margin-bottom: 0;
      color: ${theme.colors.primary};
      transition: color 0.2s ease-in-out;
      margin-top: 3rem;

      &:hover {
        color: ${theme.colors.primaryHover};
      }
    }

    .underline {
      text-decoration: underline;
      margin: 0;
    }

    div p {
      margin: 0;
      font-size: 0.8em;
      padding-top: 1rem;
    }

    a {
      color: ${theme.colors.bodyFont};

      &.read-more {
        color: ${theme.colors.primary};
        margin: 1rem 0 0;
        display: inline-block;

        &:hover {
          color: ${theme.colors.primaryHover};
        }
      }
    }
  }
`

export default StyledSidebar
