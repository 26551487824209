import { css } from "@emotion/core"
import theme from "../../../particles/Theme"

const StyledButton = css`
  background: #f15d2e;
  color: white;
  padding: 5px 10px;
  font-weight: 700;
  width: 391px;
  font-size: 2.6rem;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;

  @media (max-width: 1600px) {
    width: 300px;
  }

  @media (max-width: ${theme.breakpoints[2]}) {
    width: 225px;
    font-size: 2.3rem;
  }

  &:hover {
    background: #d54927;
    color: white;
  }
`

export default StyledButton
